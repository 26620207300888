// Galerie Jomie Pâtisserie
jQuery(document).ready(function () {
    jQuery("#jomie_gallery").nanogallery2({
        locationHash: true,
        itemsBaseURL: 'https://cdn.shopify.com/s/files/1/0551/3048/8888/files/',
        items:[
            { src: "ecd82fd1-e02a-4ae4-b5ef-7a22388ff491_w.jpg", title: "Abeilles et Marguerites" },
            { src: "db65c95d-39f8-478d-b8ab-ca4a1bd2ba59_w.jpg", title: "Amour de Stitch" },
            { src: "8b95cb94-f683-41d0-acae-519e8ce1b340_w.jpg", title: "Babe Noir et Or" },
            { src: "8a89464e-1448-4d42-8a39-317558e505ae_w.jpg", title: "Baby Girl" },
            { src: "9ae90de7-19aa-4f51-8925-c8479190fa0f_w.jpg", title: "Baby Shower Chaussons" },
            { src: "50dca9fb-c91a-42e4-83ac-00fe1c6da27a_w.jpg", title: "Baby Shower Corde à Linge" },
            { src: "e1691aa2-1022-4ed2-b009-adefc0dc1f8a_w.jpg", title: "Baby Shower Duo 2" },
            { src: "cff5e6d9-cfd1-4cfa-be7d-6b2ac99f5388_w.jpg", title: "Baby Shower Éléphants" },
            { src: "b3788916-2216-4db0-8877-c4fef1f3b832_w.jpg", title: "Baby Shower Oursonne" },
            { src: "e3a60e08-981a-4f0f-bac2-6dc8b88ecbee_w.jpg", title: "Ballerine Arabesque" },
            { src: "bacc61b7-344e-4af3-8802-933230abe7df_w.jpg", title: "Ballerine Arabesque 2" },
            { src: "482f456a-4982-477f-9980-047c6db2a8e9_w.jpg", title: "Ballerine Paris" },
            { src: "91a774af-677d-48cb-9fb3-14a4f39b872a_w.jpg", title: "BBQ" },
            { src: "390629f1-a31a-4b04-a81a-25476218b470_w.jpg", title: "Bébé Dinosaure" },
            { src: "7e33487e-2361-4d44-bbc1-a6ddd7124a92_w.jpg", title: "Bébé Minnie" },
            { src: "207d22c1-7cf8-4a20-8a4c-f4c974a96dbf_w.jpg", title: "Boîte Plaisir d'Occasion" },
            { src: "7660354d-af10-4146-b656-1b1eb1b88846_w.jpg", title: "Bricoleur 2" },
            { src: "80519080-ce3e-4bf0-b346-9d4a9f814e66_w.jpg", title: "Caramel et Café" },
            { src: "25795e07-41ef-4a33-9ab5-569e59f17e45_w.jpg", title: "Champagne et Élégance" },
            { src: "0630b7c6-423f-4af4-82e0-fa344cb0f81a_w.jpg", title: "Chat Fancy" },
            { src: "88a2f218-2701-466e-a98a-7b61b80a5e98_w.jpg", title: "Chat Fancy 2" },
            { src: "acff748a-df6d-4df4-9c3f-78a42ba40f82_w.jpg", title: "Chateau de Princesses" },
            { src: "f7318c4d-0256-477c-a1d3-0d27410f4687_w.jpg", title: "Citrons" },
            { src: "b5e67356-0ef6-45be-9091-b40515948b1d_w.jpg", title: "Coeur Passion" },
            { src: "5709517f-6e20-4d12-9ea2-6f403e530de4_w.jpg", title: "Collide With The Sky" },
            { src: "a44b7518-54dd-474e-a36b-d77d503e8841_w.jpg", title: "Combo Smash Cake - Animaux 1" },
            { src: "618b483e-672a-4edd-a1b0-c15fa33c332d_w.jpg", title: "Combo Smash Cake - Bandes 1" },
            { src: "78b25805-b794-4dfe-918d-809c7ad708e8_w.jpg", title: "Combo Smash Cake - Cupcakes 1" },
            { src: "79a7c9aa-e83f-4331-afa7-1bdc718545e6_w.jpg", title: "Combo Smash Cake - Fraises 1" },
            { src: "5a67df6c-69ff-4742-a5eb-998b6d6ebe62_w.jpg", title: "Combo Smash Cake - Jungle 1" },
            { src: "6527204f-7171-41ec-9615-e910a49a8175_w.jpg", title: "Combo Smash Cake - Ourson 1" },
            { src: "07c79731-41cd-4d63-8601-9a56f64acc50_w.jpg", title: "Couteau Stephen King" },
            { src: "cc979b4c-a69d-441f-a4e6-c798415b7a1c_w.jpg", title: "Disco 70s 80s" },
            { src: "845ff31f-dfa7-495e-9ff1-f1d193949939_w.jpg", title: "Fillette et Ballons" },
            { src: "c7c92b53-3ad2-4145-97b1-437ce7de543d_w.jpg", title: "Flammes Noires et Or" },
            { src: "9fca7dc1-6a79-4fbb-ab80-4109ef9c198a_w.jpg", title: "Fleurs Macarons 70" },
            { src: "081d7006-1fc4-431a-aedd-2f809ca45793_w.jpg", title: "Foxy Animatronic" },
            { src: "8ab2cd00-96f6-4e6b-8c1f-2c8021628358_w.jpg", title: "Gâteau Oréo Salé/Sucré" },
            { src: "c5faf415-5de1-4e11-a0e8-73e6e172664c_w.jpg", title: "Grenouille et Roseaux" },
            { src: "8f9520f8-819f-4d0c-a0f2-353444913715_w.jpg", title: "Gucci 1" },
            { src: "43fac7b4-29f3-426f-b7e3-5a7bf5ac1213_w.jpg", title: "Infirmière Coeur" },
            { src: "b94fd6b8-63dc-4ae5-8bb8-d883395140f4_w.jpg", title: "Lacoste Blanc et Or" },
            { src: "b9a7b586-eafe-4641-ba7d-89e24986c15a_w.jpg", title: "Lapin Dormeur" },
            { src: "28a1b447-3c1f-4903-acac-4e361b5ece59_w.jpg", title: "Licorne Arc-En-Ciel" },
            { src: "2324b7e4-233f-43d2-819b-33666664a4ae_w.jpg", title: "Licorne Chat" },
            { src: "31bb7d28-75d7-4b2e-ad46-5e303a766aec_w.jpg", title: "Licorne Mini" },
            { src: "f3425ac3-13ec-4fa4-b985-f774c281babf_w.jpg", title: "Licorne Petit Coeur" },
            { src: "5aec476a-08a6-4ed1-8ad6-e53e2576ac37_w.jpg", title: "Licorne Zen" },
            { src: "d3bbf234-831e-47c8-a25e-e4ade4dea482_w.jpg", title: "Licorne Zen 2" },
            { src: "c6479848-b15c-4bfb-82cb-7e0b006ec7b4_w.jpg", title: "Lion 1" },
            { src: "59b24111-26ae-4b91-a21f-26ab89f9387b_w.jpg", title: "Mariage Initiales et Fleurs" },
            { src: "4a934fbd-8789-418a-8c81-6d5242e90de4_w.jpg", title: "Mariage Perles et Fleurs" },
            { src: "02cca4e7-207c-425b-afd6-42588209fefa_w.jpg", title: "Mariage YW" },
            { src: "a37fc692-d9b6-48a5-8e34-90b3937a4b21_w.jpg", title: "Meilleurs Amis" },
            { src: "3be15236-5244-49ae-8636-84840c408f80_w.jpg", title: "Minecraft 1" },
            { src: "9ceca2c3-eba4-4dd4-9af5-f89e9883e9fb_w.jpg", title: "Minion 1" },
            { src: "8ceeaf10-55e8-44c6-8b33-69146ad85889_w.jpg", title: "Nuage Fruité Mascarpone" },
            { src: "1d12a9aa-14ae-4f59-b370-c3f5203b980e_w.jpg", title: "Oh Baby Classique" },
            { src: "2cccf497-acc9-4eba-ab60-1f527aea954a_w.jpg", title: "Petit Bâteau" },
            { src: "84a77b9e-bd86-4ba2-8210-6e26dba6d8c9_w.jpg", title: "Petite Sirène 2" },
            { src: "70e5ad5c-38ad-4e84-9627-9a2ea268572f_w.jpg", title: "Plage et Étoile de Mer" },
            { src: "0a7f66e8-a64e-4e4c-8cd9-b990b38dc14e_w.jpg", title: "Pokemon Evee" },
            { src: "56df21d2-dca9-478d-82ed-ce1edc080370_w.jpg", title: "Pot de fleurs" },
            { src: "146a08c8-0fa0-4a0b-9344-b93d7e742558_w.jpg", title: "Queues de Sirène 3" },
            { src: "9c112cbc-e4ba-4931-be49-d3163168df1b_w.jpg", title: "Reine des Neiges 1" },
            { src: "de85df6d-1ef2-4f8a-9eba-beacb85e67cf_w.jpg", title: "Reine des Neiges 2" },
            { src: "cd181539-44a7-4667-a918-1fde6a7c360f_w.jpg", title: "Reine des Neiges 3" },
            { src: "fa3e1d2b-199d-4207-b77d-d3fb678385a5_w.jpg", title: "Reine des Neiges 4" },
            { src: "08397873-4bb9-473a-9e79-be2fe89ef2bc_w.jpg", title: "Reine des Neiges 5" },
            { src: "25677603-c223-4a37-bf00-0f0c0c443168_w.jpg", title: "Renard Simple" },
            { src: "bd1e9fd3-1aa5-4c5b-a81d-cc9c2430d162_w.jpg", title: "Robe de Poupée" },
            { src: "201369cf-fc85-4c60-b99b-3c49ccf520a5_w.jpg", title: "Rose Couture " },
            { src: "5d6ceac0-4c46-4e3f-b36f-d0ac7ec4076f_w.jpg", title: "Sac à Main 2" },
            { src: "c28af359-65f7-444a-a636-434c0eef6836_w.jpg", title: "Smash Cake Alessia" },
            { src: "9036d21c-a7c7-4ffe-9fda-56da9eec6a7b_w.jpg", title: "Smash Cupcake" },
            { src: "ba385f12-4706-4b68-a0b1-836324f0b593_w.jpg", title: "Sous l'eau" },
            { src: "72c35caa-fb3d-41ff-ab59-b91c120e1567_w.jpg", title: "Spiderman 1" },
            { src: "57cec064-6849-4d30-9f16-6b92169316fb_w.jpg", title: "Sunshine" },
            { src: "c1f5d7c0-ca9e-4e92-ade2-6f611a2703e0_w.jpg", title: "Tableau Cadre Or" },
            { src: "66783e25-1e75-4f27-a2e5-75012f1cab96_w.jpg", title: "Toutou-Doudou Lapin" },
            { src: "d352f1ab-b746-40c4-ba03-b1f39d8fe3d2_w.jpg", title: "Valise Vol 70" },
            { src: "98c2bb24-af05-4d78-9d37-db027f9518dc_w.jpg", title: "Vif d'Or " },

            ],

        galleryTheme: {
            thumbnail: {
                background: 'transparent',
                backgroundImage: 'linear-gradient(315deg, rgba(254,251,247,1) 0%, rgba(249,244,237) 90%)',
                titleBgColor: 'transparent',
                titleShadow: '#FFF',
                descriptionShadow: '#FFF',
                titleColor: 'rgba(256,256,256,1)',
                borderRadius: 'transparent',
                borderColor: 'transparent'
            }
        },
        thumbnailWaitImageLoaded: true,
        thumbnailDisplayTransition: 'scaleUp',
        thumbnailHoverEffect2: 'toolsSlideDown|label_backgroundColor_rgba(249,243,236,0.8)_rgba(98,62,23,0.7)',
        thumbnailToolbarImage: { topRight: 'share'},
        thumbnailBorderVertical: 0,
        thumbnailBorderHorizontal: 0,
        thumbnailGutterWidth: 20,
        thumbnailGutterHeight: 20,
        thumbnailWidth: 200,
        thumbnailHeight: 300,
        thumbnailLabel: {
          valign: "center",
          position: 'overImage',
          titleFontSize: '1.1em',
        },
        viewerTheme: 'dark',
        viewerHideToolsDelay: 60000000,
        viewerGalleryTWidth: 60,
        viewerGalleryTHeight: 80,
        viewerToolbar: { display: true },
        viewerTools: {
            topLeft: 'pageCounter',
            topRight: 'closeButton'
        },
    });
});

